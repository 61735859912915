import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import Jobslist from "./components/Jobslist/Jobslist";
import Candidatelist from "./components/Candidatelist/Candidatelist";
import QuestionList from "./components/QuestionaireList/QuestionList";
import AddQuestion from "./components/QuestionaireList/AddQuestion/AddQuestion";
// import EditQuestion from "./components/QuestionaireList/EditQuestion";
import Login from "./components/authentication/login/login";
import ToasterComponent from "./components/ToasterComponent/ToasterComponent";
import { useEffect } from "react";
import ResumeParser from "./components/ResumeParser/ResumeParser";
import { jwtDecode } from "jwt-decode";
import { useLocation, useNavigate } from "react-router";
import toast, { Toaster } from "react-hot-toast";
import AdminDashboard from "./components/AdminDashboard/AdminDashboard";
import Setting from "./components/Settings/Setting";
import LandingPage from "./components/LandingPage";
import TenantList from "./components/SuperAdmin/Tenant/tenantList";
import AddTenant from "./components/SuperAdmin/Tenant/addTenant";
import PlanList from "./components/SuperAdmin/Plans/planList";
import AddPlans from "./components/SuperAdmin/Plans/addPlans";
import "./Styles/sassStyles.css";
import "./Styles/SasStyles";
import ViewRecordingsPage from "./components/ViewRecordings/ViewRecordingsPage";
import SuperAdminDashboard from "./components/SuperAdminDashboard/SuperAdminDashboard";
import SharedInterview from "./components/ViewRecordings/SharedInterview";
// import { getTheme } from "../src/Styles/ThemeChange";
import AuditLog from "./components/AuditLog/AuditLog";
import AddNewQuestion from "./components/QuestionaireList/AddQuestion/AddNewQuestion";
import InitialPage from "./components/InitialPage/InitialPage";
import SignUp from "./components/SignUp/SignUp";
import SetPassword from "./components/SetPassword/SetPassword";
// import EmailVarificationScreen from "./components/SetPassword/EmailVarificationScreen";
import SuccesssPage from "./components/SetPassword/SuccessPage";
// import SignIn from "./components/AzureLogin/SignIn";
import { commonUrl } from "./global";
import KanbanBoard from "./components/KanbanBoard/KanbanBoard";
import ErrorPage from "./components/Reusable/ErrorPage";
import ForgotPassword from "./components/ForgotPassword/forgotPassword";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  let role = localStorage.getItem("current_role_key");
  let token = localStorage.getItem("auth_token");
  const searchParams = new URLSearchParams(location.search);
    const view = searchParams.get("view");
  const domainName = window.location.origin;
  console.log("domainName", domainName);

  const recuirter = role === "talentou_recruiter" && token ? true : null;
  const admin = role === "talentou_admin" && token ? true : null;
  const techlead = role === "talentou_tech_lead" && token ? true : null;
  const superAdmin = role === "talentou_super_admin" && token ? true : null;

  const runLogoutTimer = (timer) => {
    setTimeout(() => {
      toast.error("Session Expired");
      setTimeout(() => {
        sessionStorage.clear();
        localStorage.clear();
        if (window.location.origin === commonUrl) {
          navigate("/");
        } else {
          navigate("/SignIn");
        }
        window.location.reload();
      }, 3000);
    }, timer);
  };

  useEffect(() => {
    // getTheme();

    // eslint-disable-next-line
    if (token) {
      var decoded = jwtDecode(token);
      const date_to = new Date(decoded.exp * 1000);
      const cur_date = new Date(Date.now());
      runLogoutTimer(date_to.getTime() - cur_date.getTime());
    }
    // eslint-disable-next-line
  }, []);

  // Get the current URL
  const currentUrl = window.location.href;
  // Check if the URL contains "/interview/view-recordings/"
  if (currentUrl.includes("/interview/view-recordings/")) {
    // Do something when the URL contains "/interview/view-recordings/"

    const match = window.location.pathname.match(
      /\/interview\/view-recordings\/[a-zA-Z0-9-]+/
    );

    // Check if a match is found
    if (match) {
      const extractedPart = match[0];

      // Store the extracted part in localStorage
      localStorage.setItem("sharedLink", extractedPart);
      const startIndex =
        localStorage.getItem("sharedLink").indexOf("view-recordings/") +
        "view-recordings/".length;

      // Get the substring from startIndex to the end of the URL
      const lastElement = localStorage
        .getItem("sharedLink")
        .substring(startIndex);

      // Store the last element in localStorage
      localStorage.setItem("interview-id", lastElement);
    } else {
    }
  }

  console.log("view110",view)

  return (
    <div className="App">
      <Toaster />

      <ToasterComponent />

      <Routes>
        {superAdmin && token && (
          <Route
            path="/superAdmin-dashboard"
            element={<SuperAdminDashboard />}
          />
        )}
        <Route
          path="/"
          exact
          element={
            token ? (
              <Navigate to="/admin-dashboard" />
            ) :(domainName === commonUrl && !view) ? (
              <InitialPage />
            ) : (
              <LandingPage />
            )
          }
        />
        {(admin || recuirter || techlead) && (
          <Route path="/jobslist" element={<Jobslist />} />
        )}
        {token && (admin || recuirter || techlead) && (
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
        )}
        <Route path="/SignIn" element={<LandingPage />} />
        <Route path="/SignUp" element={<SignUp />} />
        {/* <Route path="/set-password/:uuid/:token" element={<SetPassword/>}></Route> */}
        <Route path="/verify-account/:uuid/:token" element={<SetPassword />} />
        <Route path="/success" element={<SuccesssPage />} />

        {(admin || recuirter || techlead) && (
          <Route
            path="/interview/view-recordings/:intid"
            element={<ViewRecordingsPage />}
          />
        )}
        {(admin || recuirter || techlead) && (
          <Route
            path="/interview/candidatelist/:jobid"
            element={<Candidatelist />}
          />
        )}

        <Route
          path="/interview/candidatelist/resumeparser"
          element={<ResumeParser />}
        />
        <Route path="*" element={<Navigate to="/" />} />
        {(admin || techlead) && (
          <Route path="/questionnaires" element={<QuestionList />}></Route>
        )}
        {(admin || techlead) && (
          <Route path="/addnewquestion" element={<AddQuestion />}></Route>
        )}
        {(admin || techlead) && (
          <Route
            path="/addnewquestion/:jobid"
            element={<AddQuestion />}
          ></Route>
        )}
        {/* {(admin || techlead) && (
          <Route path="/editquestion/:id" element={<EditQuestion />}></Route>
        )} */}
        {/* {(admin || techlead) && (
          <Route
            path="/viewquestion/:viewid"
            element={<EditQuestion />}
          ></Route>
        )} */}
        <Route path="/share/interview/auth/:id/:mode?" element={<Login />}></Route>
        <Route path="/settings/:type" element={<Setting />}></Route>
        <Route path="/tenant" element={<TenantList />}></Route>
        <Route path="/plans" element={<PlanList />}></Route>
        <Route path="/tenant/tenantDetails" element={<AddTenant />}></Route>
        <Route path="/plans/planDetails" element={<AddPlans />}></Route>
        <Route path="/audit-log" element={<AuditLog />}></Route>
        <Route path="/kanban-board" element={<KanbanBoard />}></Route>
        <Route
          path="/interview/recording/:id"
          element={<SharedInterview />}
        ></Route>
        <Route path="/question" element={<AddNewQuestion />}></Route>
        <Route path="/error" element={<ErrorPage />}></Route>
        <Route path="/forgot-password" element={<ForgotPassword/>}></Route>
      </Routes>
    </div>
  );
}

export default App;
